<template>
    <div class="js-entry o-entry c-menu-entry" :class="cType" role="navigation">
        <ul class="c-menu-entry" role="list">
          <li v-for="item in this.entryData.MenuItems" :key="item.id" role="listitem">
            <router-link tag="a" :to="'/page/' + item.ID" :class="{'no-thumbnails': bNoThumbnails}" class="c-menu-entry__item">
              <xp-bg-img v-if="item.Icon.Type == 'binary' && !bNoThumbnails" :tag="'div'" class="c-menu-entry__item-imagewrapper"
                 :src="$xp.content.getImageUrl(item.Icon.Image, getThumbnailWidth())" :class=item.Icon.Type
                 :placeholderSrc="item.Icon.Image.Thumb"
              />
              <span v-else-if="item.Icon.Type == 'icon'" class="xp" :class=item.Icon.ID aria-hidden="true"></span>
              <span v-else class="xp xp--large xp-space" aria-hidden="true"></span>
              <div class="c-menu-entry__item-textwrapper" :aria-label="getReadout(item)">
                <div class="c-menu-entry__item-text" aria-hidden="true">{{ item.Title }}</div>
                <div class="c-menu-entry__item-subtitle" aria-hidden="true">{{ item.SubTitle }}</div>
                <div class="c-menu-entry__item-metatags" aria-hidden="true">
                  <template v-for="(value, index) in item.MetaTags">
                    <span v-bind:key="'tag' + index">
                      <span class="xp" :class=aMetaTagIcons[index]></span>
                      <span class="c-menu-entry__item-metatag">{{ value }}</span>
                    </span>
                  </template>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
    </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import XpBgImg from '../XpBgImg'

export default {
  mixins: [entryMixin],
  components: { XpBgImg },
  mounted () {
    this.$el.addEventListener('touchmove', function (event) {
      event.handled = true
      /* Is handled by default scroll behavior of browser */
    }, false)
  },
  data () {
    return {
      bNoThumbnails: this.entryData.Json && this.entryData.Json.ShowThumbnails ? !this.entryData.Json.ShowThumbnails : true,
      aMetaTagIcons: {
        stationen: 'xp-map-marker',
        dauer: 'xp-clock-o'
      },
      cType: this.entryData.Json && (this.entryData.Json.Type === 'branch' || this.entryData.Json.Type === 'list') ? 'list' : this.entryData.Json.Type
    }
  },
  methods: {
    getThumbnailWidth () {
      for (const w of this.$xp.settings.mergedSettings.content.imageResolutions) {
        if (this.cType === 'gallery' && w >= 400) {
          return (w)
        } else if (this.cType !== 'gallery') {
          return (w)
        }
      }
    },
    // Text fuer Vorlesebrowser aufbereitet, damit nicht einzelne Elemente durchlaufen werden muessen. Doppelpunkte fuer Pausen.
    getReadout (item) {
      let cReadOutText = ''
      cReadOutText += item.Title
      if (item.SubTitle > '') cReadOutText += ':' + item.SubTitle
      cReadOutText += this.getMetaTagsReadout(item.MetaTags)

      return cReadOutText
    },
    getMetaTagsReadout (aMetaTags) {
      let cReadOutText = ''
      for (const key in aMetaTags) {
        cReadOutText += ':' + key + ':' + aMetaTags[key]
      }
      return cReadOutText
    }
  }
}
</script>
